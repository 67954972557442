.individual_qr_container{
    font-size: 25px;
    font-weight: bold;
    color:rgb(72, 67, 67);
    padding-top:10px;
    padding-bottom:10px;
    text-align: center;
    -webkit-box-shadow: 0px 0px 18px -10px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 18px -10px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 18px -10px rgba(0,0,0,0.75);
    width:80%;
    margin: auto;
    box-sizing:border-box;
    -webkit-box-sizing:border-box;
    }
    
    .individual_qr_container:hover{
        background: rgb(81, 189, 226);
        border-bottom: 4px solid blue;
        margin-bottom: -1px;
        cursor: pointer;
    }
    #qr_home_container{
        display:flex;
        flex-direction: column;
        justify-content: center;
        gap:30px;
        margin-top:15px;
    
    }
    
    #big_text_top_home{
    font-size: 45px;
    display: block;
    width:90%;
    font-weight: bold;
    color:rgb(72, 67, 67)
    }
    
    #smaller_text_bottom{
    color:rgb(183, 166, 166);
    width:50%;
    }
    
    #show_nextsteps{
        display:flex;
        flex-direction: column;
        justify-content: center;
        gap:30px;
        margin-top:15px;
        text-align: center;
    }
    #show_more_link{
    color:rgb(10, 10, 55);
    }
    #show_more_link:hover{
       cursor: pointer;
        }
    #next_step_btn{
        width:30%;
        margin:auto;
    }
    #create_text{
        font-size: 45px;
    display: block;
    width:90%;
    font-weight: bold;
    color:rgb(72, 67, 67);
    text-align: center;
    }
    .steps_container{
        display:flex;
        flex-direction: column;
        justify-content: center;
        gap:30px;
        margin-top:15px;
        text-align: center;
        margin-left:auto;
        margin-right:auto;
        background:rgb(243, 238, 238);
        border-radius: 5px;
        padding:5px;
        -webkit-box-shadow: 0px 0px 18px -10px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 18px -10px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 18px -10px rgba(0,0,0,0.75);
    }
    
    .steps{
        width:200px;
        margin:auto;
    }
    .step_text{
        color:rgb(58, 58, 238);
        border-radius: 5px;
        padding-left:10px;
        padding-right:10px;
        background:rgb(205, 205, 230);
        font-weight: 400;
        width:fit-content;
        font-family: Arial, Helvetica, sans-serif;
        margin:auto;
    }
    .step_text_directions{
        font-size: 30px;
    display: block;
    width:100%;
    font-weight: bold;
    color:rgb(72, 67, 67)
    }
    
    #powering_text{
        text-align: center;
        font-size: 45px;
        display: block;
        width:100%;
        font-weight: bold;
        color:rgb(72, 67, 67)
    }
    .container_howmany
    {
        display:flex;
        flex-direction: column;
        justify-content: center;
        gap:30px;
        margin-top:15px;
        text-align: center;
        margin-left:auto;
        margin-right:auto;
        background:rgb(243, 238, 238);
        border-radius: 5px;
        padding:5px;
        -webkit-box-shadow: 0px 0px 18px -10px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 18px -10px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 18px -10px rgba(0,0,0,0.75);
    }
    .amount_text{
    color:blue;
    font-size:35px;
    }
    .description_text{
        color:rgb(131, 131, 135);
        font-size:25px;
    }
    .icon_numbers{
        color:blue;
    font-size:35px;
    }
    #all_features{
        font-size: 45px;
    display: block;
    width:90%;
    font-weight: bold;
    color:rgb(72, 67, 67);
    text-align: center;
    }
    .qr_types{
    padding:10px;
    background:white;
    height:75px;
    width:150px;
    border-radius: 10px;
    
    }
    .qr_types:hover{
        cursor: pointer;
    }
    #different_types_qr_container{
        display:flex;
        flex-direction:row;
        justify-content: center;
        gap:30px;
        margin-top:15px;
        text-align: center;
        margin-left:auto;
        margin-right:auto;
        background:rgb(243, 238, 238);
        border-radius: 5px;
        padding:5px;
        flex-wrap: wrap;
        padding-top:50px;
        padding-bottom:50px;
        
    }
    .emblem{
        color:blue;
        display: block;
        font-size: 30px;
    }
    #all_features_container{
        display:flex;
        flex-direction:row;
        justify-content: center;
        align-items: center;
        gap:30px;
        margin-left:auto;
        margin-right:auto;
        background:rgb(243, 238, 238);
        border-radius: 5px;
        flex-wrap: wrap;
        padding-top:50px;
        padding-bottom:50px;
    
    }
    .featuresicon{
        font-size: 50px;
        color:blue;
        margin-right:10px;
    }
    .features_item{
        padding:10px;
        background:white;
        height:75px;
        width:330px;
        border-radius: 10px;
        text-align: center;
        
    }
    #submit_email_btn{
        margin-left:5px;
    }
    #coming_text{
       font-size: 40px;
       font-weight: 500; 
    }
    #coming_text_next{
        font-size:30px;
        font-weight: 500; 
        color:rgb(169, 169, 223)
    }
    #large_map{
        width:100%;
    }
    .feature_text{
        font-size: 15px;
        display: block;
        width:90%;
        font-weight: bold;
        color:rgb(58, 53, 53);
    
    }
    #try_for_free_btn{
        width:30%;
        margin:auto;
    }
    
    @media screen and (max-width: 576px) {
        #next_step_btn
        {
            width:100%;
           
        }
        #try_for_free_btn{
            width:100%;
            margin:auto;
        }
      }
      